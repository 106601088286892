import React, { useMemo } from 'react'
import type { HeadFC, PageProps } from 'gatsby'
import { Typography } from '@wicadu/arepa/ui'
import { isBrowser } from '@wicadu/arepa/utils'
import styled from '@emotion/styled'

export const translate: any = {
  es: {
    TITLE: 'Versión del Centro de ayuda',
    DESCRIPTION: 'Mantente al tanto de las últimas características, correcciones de errores y mejoras en nuestras notas de la versión'
  },
  en: {
    TITLE: 'Help Center version',
    DESCRIPTION: 'Stay updated with the latest features, bug fixes, and improvements in our release notes'
  }
}

const companyName: string = 'wicadu'
const version: string = `${process.env.VERSION}-${process.env.WENV}`
const url: string = `${process.env.HELP_WEBAPP_HOST}`

const wicaduCover: string = `${process.env.HELP_WEBAPP_HOST}/logos/logo.png`
const wicaduCoverWidth: number = 600
const wicaduCoverHeight: number = 600

const defaultLang: string = 'en'
const defaultLocale: string = 'en'

export const Head: HeadFC = () => {
  const { lang, locale } = useMemo(() => {
    if (isBrowser()) {
      const navigatorLang: string = isBrowser() ? navigator.language : defaultLang

      const lang: string = navigatorLang.startsWith('es') ? 'es' : defaultLang
      const locale: string = lang === 'es' ? 'es_419' : defaultLocale

      return { lang, locale }
    }

    return { lang: defaultLang, locale: defaultLocale }
  }, [])

  const title: string = useMemo(() => translate[lang].TITLE, [lang])
  const description: string = useMemo(() => translate[lang].DESCRIPTION, [lang])

  return (
    <>
      <html lang={lang} />
      <meta charSet='utf-8' />
      <meta name='robots' content='noindex, nofollow' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      <title>{`${title} | ${companyName}`}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={url} />

      <meta property='og:locale' content={locale} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={wicaduCover} />
      <meta property='og:url' content={url} />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@wicaduapp' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={wicaduCover} />
      <meta name='twitter:image:width' content={wicaduCoverWidth} />
      <meta name='twitter:image:height' content={wicaduCoverHeight} />

      <meta name='twitter:creator' content='@wicaduapp' />

      <script type='application/ld+json'>
        {`{
          "@context": "http://schema.org",
          "@type": "SoftwareApplication",
          "name": "${title}",
          "description": "${description}",
          "applicationCategory": "WebApplication",
          "operatingSystem": "All",
          "softwareVersion": "${version}",
          "image": {
            "@type": "ImageObject",
            "url": "${wicaduCover}",
            "width": "${wicaduCoverWidth}",
            "height": "${wicaduCoverHeight}"
          },
          "url": "${url}",
          "provider": {
            "@type": "Organization",
            "name": "${companyName}",
            "url": "${process.env.LANDING_PAGE_HOST}"
          }
        }`}
      </script>
    </>
  )
}

const Version: React.FC<PageProps> = () => {
  const lang = useMemo(() => {
    if (isBrowser()) {
      const navigatorLang: string = isBrowser() ? navigator.language : defaultLang
      return navigatorLang.startsWith('es') ? 'es' : defaultLang
    }

    return defaultLang
  }, [])

  return (
    <Container>
      <Typography type='title'>{translate[lang].TITLE}</Typography>
      <Typography type='description'>{translate[lang].DESCRIPTION}</Typography>
      <CurrentVersion type='description'>{version}</CurrentVersion>
    </Container>
  )
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 20px;
`

const CurrentVersion = styled(Typography)`
  &:before {
    content: "v ";
  }
`

export default Version
